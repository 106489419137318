'use client';
import { useState } from 'react';
import { X } from 'react-feather';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { UNAUTH_API } from '@/api';
import { Drawer, Selectbox } from '@/components/client';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './TermsDrawer.module.scss';

export const TermsDrawer = () => {
  const [termsDrawerType, setTermsDrawerType] = useGlobalStore((state) => [
    state.termsDrawerType,
    state.setTermsDrawerType,
  ]);

  const { data } = useQuery({
    queryKey: ['terms', termsDrawerType],
    enabled: !!termsDrawerType,
    queryFn: () => {
      if (!termsDrawerType) return null;

      return UNAUTH_API.getTermsList({
        termCategory: termsDrawerType,
      });
    },
  });

  const [order, setOrder] = useState(0);
  const terms = data?.context?.contents;
  const selectedTerms = terms?.[order];
  const newLineFilterContents = selectedTerms?.contents?.replace(/\n/g, '');

  return (
    <Drawer opened={!!termsDrawerType} onClose={() => setTermsDrawerType(null)}>
      <header className={styles.header}>
        <h2>{selectedTerms?.title}</h2>
        <X onClick={() => setTermsDrawerType(null)} />
      </header>
      <div
        className={styles.contents}
        dangerouslySetInnerHTML={{
          __html: newLineFilterContents || '',
        }}
      />
      <footer className={styles.footer}>
        <Selectbox
          float="top"
          options={terms?.map(({ title, create_at }, index) => ({
            label: `${title} ${dayjs(create_at).format('YYYY.MM.DD')}`,
            value: index,
          }))}
          value={order}
          onChange={(e) => setOrder(e.value)}
        />
      </footer>
    </Drawer>
  );
};
