import { useLayoutEffect } from 'react';

import { usePathname } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { MEMBER_JOIN_STATUS } from '@/api';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useAuthStore } from '@/stores/AuthStore';

export const useJoinStepControl = () => {
  const [memberJoinStatus, isLogouting] = useAuthStore(
    useShallow((state) => [state.myInfo?.memberJoinStatus, state.isLogouting]),
  );

  const router = useCustomRouter();
  const pathname = usePathname();

  useLayoutEffect(() => {
    if (isLogouting) return;

    if (!pathname || !memberJoinStatus) return;

    //! 회원가입 중인 경우 따로 리다이렉트 없이 진행
    if (pathname.includes('/join/')) return;

    //! 간편 인증 페이지에서는 회원가입 페이지로의 리다이렉트를 막기 위함
    if (pathname.includes(ROUTES.EASY_CERTIFICATION.pathname)) return;

    //! 해당 페이지에서는 회원가입 페이지로의 리다이렉트를 막기 위함
    if (
      [ROUTES.PROFILE_EDIT.pathname, ROUTES.LOGIN.pathname].some(
        (path) => pathname === path,
      )
    )
      return;

    switch (memberJoinStatus) {
      case MEMBER_JOIN_STATUS.NO_NICKNAME: {
        return router.replace(ROUTES.JOIN_USER_INFO.pathname);
      }

      case MEMBER_JOIN_STATUS.EMPTY_FILTER:
      case MEMBER_JOIN_STATUS.WEAKNESS_FILTER: {
        if (
          pathname.includes(ROUTES.JOIN_FILTER_SELECT.pathname) ||
          pathname === ROUTES.JOIN_INTERESTED_FILTER.pathname
        )
          return;

        return router.replace(ROUTES.JOIN_FILTER_SELECT.pathname);
      }

      case MEMBER_JOIN_STATUS.NO_PHONE: {
        return router.replace(ROUTES.PROFILE_EDIT.pathname);
      }
    }
  }, [memberJoinStatus, router, pathname, isLogouting]);
};
