'use client';

import { useEffect, useRef } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { STORAGE_KEY } from '@/constants';
import { useGlobalStore } from '@/stores/GlobalStore';

export const HistoryTracker = () => {
  const [isMounted, setHistoryMap, setCurrentHistoryKey] = useGlobalStore(
    useShallow((state) => [
      state.isMounted,
      state.setHistoryMap,
      state.setCurrentHistoryKey,
    ]),
  );

  const searchParams = useSearchParams();
  const pathname = usePathname();

  const href = (() => {
    if (typeof window === 'undefined' || !pathname || !searchParams)
      return null;

    const url = new URL(pathname, location.origin);

    searchParams.forEach((value, key) => url.searchParams.set(key, value));

    return url.href;
  })();

  const lastHistorySize = useRef(0);

  useEffect(() => {
    if (!isMounted || !href) return;

    //! ⚠️ 구독할 필요가 없는 값
    const { tempHistoryKey, setTempHistoryKey } = useGlobalStore.getState();

    const historyKey: number | undefined = history.state.key ?? tempHistoryKey;
    //! ⚠️ 할당 후 초기화
    if (tempHistoryKey) setTempHistoryKey(undefined);

    const historySize = history.length;

    //! ⚠️ back, forward, replace에 의한 이동
    if (historyKey) {
      setHistoryMap((prevHistoryMap) => {
        prevHistoryMap.set(historyKey, href);

        return prevHistoryMap;
      });

      setCurrentHistoryKey(historyKey);
    } else {
      const currentHistoryKeyString = sessionStorage.getItem(
        STORAGE_KEY.CURRENT_HISTORY_KEY,
      );

      if (!currentHistoryKeyString)
        throw new Error('currentHistoryKeyString is null');

      const currentHistoryKey = Number(currentHistoryKeyString);

      const nextHistoryKey = currentHistoryKey + 1;

      const newHistoryKey =
        nextHistoryKey < historySize ? historySize : nextHistoryKey;

      const currentLastHistorySize =
        lastHistorySize.current || history.length - 1;

      setHistoryMap((prevHistoryMap) => {
        //! ⚠️ 뒤로가기 후 새로운 페이지를 열었을 경우
        if (historySize < currentLastHistorySize) {
          for (let i = historySize + 1; i < currentLastHistorySize + 1; i++) {
            prevHistoryMap.delete(i);
          }
        }
        prevHistoryMap.set(newHistoryKey, href);

        return prevHistoryMap;
      });

      history.replaceState({ ...history.state, key: newHistoryKey }, '');

      setCurrentHistoryKey(newHistoryKey);
    }

    return () => {
      lastHistorySize.current = historySize;
    };
  }, [href, isMounted, setCurrentHistoryKey, setHistoryMap]);

  return null;
};
