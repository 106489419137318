'use client';

import { memo } from 'react';

import { cleanClassName, isCurrentUrl } from '@wello-client/common/utils';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import CustomPolicyOff from '@/assets/svgs/navigation/custom_off.svg';
import CustomPolicyOn from '@/assets/svgs/navigation/custom_on.svg';
import HomeOff from '@/assets/svgs/navigation/home_off.svg';
import HomeOn from '@/assets/svgs/navigation/home_on.svg';
import PolicyOff from '@/assets/svgs/navigation/policy_off.svg';
import PolicyOn from '@/assets/svgs/navigation/policy_on.svg';
import ProfileOff from '@/assets/svgs/navigation/profile_off.svg';
import ProfileOn from '@/assets/svgs/navigation/profile_on.svg';
import TownInfoOff from '@/assets/svgs/navigation/town_off.svg';
import TownInfoOn from '@/assets/svgs/navigation/town_on.svg';
import { MODULE_ROUTES, ROUTES } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';
import { useGlobalStore } from '@/stores/GlobalStore';
import { findCurrentRoute } from '@/utils';

import styles from './GlobalNavigation.module.scss';

export const GlobalNavigation = memo(() => {
  const setIsGlobalLoading = useGlobalStore(
    (state) => state.setIsGlobalLoading,
  );
  const pathname = usePathname();

  const isLogin = useAuthStore((state) => state.isLogin);

  const currentRoute = findCurrentRoute(pathname);

  if (!currentRoute?.hasBottomNav) return null;

  return (
    <nav className={styles.nav}>
      <ul className={styles['nav-contents']}>
        {[
          {
            name: '홈',
            href: ROUTES.HOME.pathname,
            icon: {
              on: HomeOn,
              off: HomeOff,
            },
          },
          {
            name: '맞춤정책',
            href: isLogin
              ? ROUTES.RECOMMEND_POLICY_LIST.pathname
              : ROUTES.LOGIN.withSearchParams({
                  searchParams: {
                    redirect: ROUTES.RECOMMEND_POLICY_LIST.pathname,
                  },
                }),
            icon: {
              on: CustomPolicyOn,
              off: CustomPolicyOff,
            },
          },
          {
            name: '동네소식',
            href: ROUTES.HOMETOWN_NEWS.pathname,
            icon: {
              on: TownInfoOn,
              off: TownInfoOff,
            },
          },
          {
            name: '정책소식',
            href: ROUTES.POLICY_INFO_LIST.pathname,
            icon: {
              on: PolicyOn,
              off: PolicyOff,
            },
          },
          {
            name: '마이페이지',
            href: isLogin
              ? ROUTES.PROFILE.pathname
              : ROUTES.LOGIN.withSearchParams({
                  searchParams: {
                    redirect: ROUTES.PROFILE.pathname,
                  },
                }),
            icon: {
              on: ProfileOn,
              off: ProfileOff,
            },
          },
        ].map(({ name, href, icon }) => {
          const isActived =
            href === '/'
              ? pathname === href || pathname === MODULE_ROUTES.KB_HOME.pathname
              : pathname?.includes(href);

          const Icon = isActived ? icon.on : icon.off;

          return (
            <li key={name}>
              <Link
                className={cleanClassName(
                  `${styles['nav-item-link']} ${isActived && styles.active}`,
                )}
                href={href}
                onClick={() => {
                  if (!isCurrentUrl(href)) setIsGlobalLoading(true);
                }}
              >
                <Icon className={styles['nav-item-icon']} />
                {name}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
});

GlobalNavigation.displayName = 'GlobalNavigation';
