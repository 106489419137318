import { useEffect, useRef } from 'react';

import { AUTH_API } from '@/api';
import { ROUTES } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';
import { WEBVIEW_TYPE, useDeviceInfoStore } from '@/stores/DeviceStore';

import { useAccessToken } from './useAccessToken';

export const useLogout = () => {
  const { accessToken, setAccessToken, refreshToken, setRefreshToken } =
    useAccessToken();

  const setIsLogouting = useAuthStore((state) => state.setIsLogouting);

  const { current } = useRef({
    logout: async () => {
      try {
        await AUTH_API.logout();
      } finally {
        setIsLogouting(true);
        setAccessToken(null);
        setRefreshToken(null);

        const isKbPayWebview =
          useDeviceInfoStore.getState().webviewType === WEBVIEW_TYPE.KB_PAY;

        if (!isKbPayWebview) {
          //! ⚠️ KB는 외부 브라우저에 로그인 되어있는 경우가 있어 로그인 페이지 진입 시 강제로 로그아웃 시키고 이후 로그인 프로세스를 진행함
          if (location.pathname !== ROUTES.LOGIN.pathname) {
            location.replace(ROUTES.HOME.pathname);
          }
        }
      }
    },
  });

  useEffect(() => {
    if (
      !accessToken &&
      !refreshToken &&
      location.pathname === ROUTES.LOGIN.pathname &&
      location.search.includes('logout=true')
    ) {
      const logoutTimeout = setTimeout(() => {
        const url = new URL(location.href);

        url.searchParams.delete('logout');
        location.replace(url);
      }, 1_000);

      return () => clearTimeout(logoutTimeout);
    }
  }, [accessToken, refreshToken]);

  return current;
};
