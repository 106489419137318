import Script from 'next/script';

import { IS_PRODUCT } from '@/constants';

export const GTM_ID = IS_PRODUCT ? 'GTM-WF9XK27' : 'GTM-NJCCF8P';

export const WebGlobalScript = () => {
  const GA_ID = IS_PRODUCT ? 'G-WKDK663EEQ' : 'G-7FCSFN8PD7';

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "${GA_ID}");
        `,
        }}
        id="GA"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "${GTM_ID}");
        `,
        }}
        id="GTM"
        strategy="afterInteractive"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            name: 'WELLO INC.',
            url: 'https://www.welfarehello.com',
            sameAs: [
              'https://blog.naver.com/wello_official',
              'https://www.instagram.com/wello_welfarehello/',
              'https://www.youtube.com/@wello_welfarehello',
            ],
          }),
        }}
        id="SEO-JSON"
        strategy="afterInteractive"
        type="application/ld+json"
      />
    </>
  );
};
