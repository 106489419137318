'use client';

import { memo, useEffect } from 'react';

import { createError } from '@wello-client/common/utils';
import { usePathname, useSearchParams } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { LoadingPageTemplate } from '@/components/server';
import { useGlobalStore } from '@/stores/GlobalStore';

export const GlobalLoading = memo(() => {
  const [isGlobalLoading, setIsGlobalLoading] = useGlobalStore(
    useShallow((state) => [state.isGlobalLoading, state.setIsGlobalLoading]),
  );

  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (isGlobalLoading) {
      const href = window.location.href;
      const timeout = setTimeout(() => {
        if (window.location.href === href) {
          setIsGlobalLoading(false);

          throw createError({
            return_message: 'Global Loading Timeout',
          });
        }
      }, 3_000);

      return () => clearTimeout(timeout);
    }
  }, [setIsGlobalLoading, isGlobalLoading]);

  useEffect(
    () => setIsGlobalLoading(false),
    [pathname, searchParams, setIsGlobalLoading],
  );

  return isGlobalLoading ? <LoadingPageTemplate blur /> : <></>;
});

GlobalLoading.displayName = 'GlobalLoading';
