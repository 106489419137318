'use client';

import { useShallow } from 'zustand/react/shallow';

import { Modal } from '@/components/client';
import { Button } from '@/components/server';
import { ROUTES } from '@/constants';
import { useAccessToken } from '@/hooks/useAccessToken';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './WelloWebLinkModal.module.scss';

export const WelloWebLinkModal = () => {
  const { accessToken, refreshToken } = useAccessToken();
  const [welloWebLinkModalProps, setWelloWebLinkModalProps] = useGlobalStore(
    useShallow((state) => [
      state.welloWebLinkModalProps,
      state.setWelloWebLinkModalProps,
    ]),
  );

  const { descriptions, link } = welloWebLinkModalProps || {};

  const closeModal = () => setWelloWebLinkModalProps(null);

  return (
    <Modal opened={!!welloWebLinkModalProps} onClose={closeModal}>
      <article className={styles.modal}>
        <h2>
          웰로 웹페이지에서
          <br />
          이용해 주세요.
        </h2>
        <p>{descriptions}</p>
        <footer className={styles.footer}>
          <Button shape="capsule" theme="primary-outline" onClick={closeModal}>
            뒤로가기
          </Button>
          <Button
            shape="capsule"
            onClick={() => {
              const welloDomain = process.env.NEXT_PUBLIC_DOMAIN;

              if (!welloDomain || !link) return;

              const linkUrl = new URL(link, location.origin);

              if (!accessToken || !refreshToken) return;

              const redirectSearchParams: {
                [key: string]: string;
              } = {};

              linkUrl.searchParams.forEach((value, key) => {
                redirectSearchParams[key] = value;
              });

              window.location.href = `external${welloDomain}${ROUTES.CROSS_DOMAIN_CALLBACK.withSearchParams(
                {
                  searchParams: {
                    accessToken,
                    refreshToken,
                    redirect: linkUrl.pathname,
                    redirectSearchParams: JSON.stringify(redirectSearchParams),
                  },
                },
              )}`;

              closeModal();
            }}
          >
            웹으로 이동
          </Button>
        </footer>
      </article>
    </Modal>
  );
};
