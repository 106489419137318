'use client';

import { memo, useEffect } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { createError } from '@wello-client/common/utils';
import { usePathname } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { UNAUTH_API, WelloAuthOpenApi, CommonAuthOpenApi } from '@/api';
import { ROUTES } from '@/constants';
import { useAccessToken } from '@/hooks/useAccessToken';
import { useLogout } from '@/hooks/useLogout';
import { authQueryOptions } from '@/query-factory';
import { useAuthStore } from '@/stores/AuthStore';
import { useGlobalStore } from '@/stores/GlobalStore';

import { useJoinStepControl } from '../_hooks/useJoinStepControl';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = memo(({ children }: AuthProviderProps) => {
  const [setIsLogin, setMyInfo, isLogin] = useAuthStore(
    useShallow((state) => [state.setIsLogin, state.setMyInfo, state.isLogin]),
  );

  const setIsGlobalLoading = useGlobalStore(
    (state) => state.setIsGlobalLoading,
  );

  const { accessToken, refreshToken, setAccessToken, setRefreshToken } =
    useAccessToken();

  WelloAuthOpenApi.TOKEN = accessToken || undefined;
  CommonAuthOpenApi.TOKEN = accessToken || undefined;

  const pathname = usePathname();

  const { logout } = useLogout();

  useEffect(() => {
    if (!pathname) return;

    if (
      (
        [
          //! ⚠️ 인증 과정중에 비로그인 처리 해버리지 않기 위함
          ROUTES.CROSS_DOMAIN_CALLBACK.pathname,
          ROUTES.AUTH.pathname,
        ] as string[]
      ).includes(pathname)
    )
      return;

    if (!accessToken && !refreshToken) {
      setIsLogin(false);
    }
  }, [accessToken, pathname, refreshToken, setIsLogin]);

  const { mutate: refreshTokenMutate, isPending: isRefreshTokenPending } =
    useMutation({
      mutationFn: UNAUTH_API.getRefreshToken,
      onError: () => {},
      onSuccess: ({ context }) => {
        const accessToken = context?.access_token;
        const refreshToken = context?.refresh_token;

        if (accessToken && refreshToken) {
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
        } else {
          logout();
          throw createError({
            return_message: 'Refresh token 발급에 실패했습니다.',
          });
        }
      },
    });

  const { data, isError: isAccessTokenError } = useQuery({
    ...authQueryOptions.myInfo({
      accessToken,
    }),
    enabled: !!accessToken && !isRefreshTokenPending,
  });

  useEffect(() => {
    if (!data) return;

    setMyInfo(data);
    setIsLogin(true);
  }, [data, setIsLogin, setMyInfo]);

  useEffect(() => {
    if (refreshToken && !accessToken) {
      refreshTokenMutate({
        refreshToken,
      });
    }
  }, [accessToken, isAccessTokenError, refreshToken, refreshTokenMutate]);

  //! 비회원 페이지에 있더라도 로그인 되어있고 회원가입이 완료되어있지 않으면 회원가입 페이지로 이동
  useJoinStepControl();

  useEffect(() => {
    setIsGlobalLoading(isLogin === undefined);
  }, [isLogin, setIsGlobalLoading]);

  const { data: accessTokenStatusData, isError } = useQuery(
    authQueryOptions.checkAccessTokenStatus(accessToken),
  );

  const isHealthy = accessTokenStatusData ?? true;

  useEffect(() => {
    if (isHealthy) return;

    setAccessToken(null);
  }, [isHealthy, setAccessToken]);

  useEffect(() => {
    if (isError) logout();
  }, [isError, logout]);

  return <>{children}</>;
});

AuthProvider.displayName = 'AuthProvider';
