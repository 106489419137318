'use client';

import Script from 'next/script';
import { useShallow } from 'zustand/react/shallow';

import { useAuthStore } from '@/stores/AuthStore';
import {
  SUB_DOMAIN,
  WEBVIEW_TYPE,
  useDeviceInfoStore,
} from '@/stores/DeviceStore';

import { KbAppController, KbHistoryStack } from './kb-pay';
import { AppController, AppModal } from './wello-app';
import { WebGlobalScript, WebGtmNoScript } from './wello-web';

export const GlobalScript = () => {
  const webviewType = useDeviceInfoStore(({ webviewType }) => webviewType);
  const [isLogin, memberCode] = useAuthStore(
    useShallow((state) => [state.isLogin, state.myInfo?.memberCode]),
  );

  return isLogin === undefined ? null : (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ memberCode: '${memberCode}' });
    window.dataLayer.push({ userEnv: '${
      {
        [WEBVIEW_TYPE.KB_PAY]: 'KB_PAY_APP',
        [WEBVIEW_TYPE.WELLO_APP]: 'WELLO_APP',
        web: 'WELLO_WEB',
      }[webviewType ?? 'web']
    }' });
    `,
        }}
        id="DATA_LAYER"
      />
      <WebGlobalScript />
    </>
  );
};

//TODO: 다른 GTM NO SCRIPT 추후 정말 필요없다고 하면 삭제 필요 (각 모듈에 있음)
export const GtmNoScript = () => <WebGtmNoScript />;

export const GlobalController = () => {
  const subDomain = useDeviceInfoStore(({ subDomain }) => subDomain);

  switch (subDomain) {
    case SUB_DOMAIN.KB_PAY:
      return (
        <>
          <KbHistoryStack />
          <KbAppController />
        </>
      );

    case SUB_DOMAIN.WELLO_APP:
      return (
        <>
          <AppController />
          <AppModal />
        </>
      );

    default:
      return <></>;
  }
};
