'use client';
import { useState } from 'react';
import { ChevronRight, X } from 'react-feather';

import Image from 'next/image';
import Link from 'next/link';

import Logo from '@/assets/svgs/logo.svg';
import Badge from '@/assets/svgs/title-badge.svg';
import { Modal } from '@/components/client';
import { useToast } from '@/hooks/useToast';

import styles from './LayoutAsideSection.module.scss';

const LayoutAsideSection = () => {
  const GOOGLE_PLAY_STORE_SHARE_URL =
    'https://play.google.com/store/apps/details?id=com.wello.welfarehello&pcampaignid=web_share';

  const APPLE_APP_STORE_SHARE_URL =
    'https://apps.apple.com/kr/app/웰로-wello-맞춤-정책-추천-신청-서비스/id6444814937?platform=iphone';

  const IMAGE_SIZE = 195;

  const shareUrl = `https://api.qrserver.com/v1/create-qr-code/?size=${IMAGE_SIZE}x${IMAGE_SIZE}&data=`;
  const [isGooglePlayModalOpen, setIsGooglePlayModalOpen] = useState(false);
  const [isAppStoreModalOpen, setIsAppStoreModalOpen] = useState(false);

  const { toast } = useToast();

  const copyToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      toast({ type: 'success', message: '링크가 복사되었습니다!' });
    }
  };

  return (
    <>
      <aside className={styles['side-section']}>
        <section className={styles['title-section']}>
          <Logo className={styles.logo} />
          <div className={styles.title}>
            <p>몰라서 놓치는 정책이 없도록</p>
            <p className={styles['heading']}>
              내게 맞는 정책 <br />
              웰로에서 찾아요!
            </p>
            <Badge className={styles.badge} />
          </div>
          <div className={styles['app-download-section']}>
            <button
              className={styles['app-download-button']}
              onClick={() => setIsGooglePlayModalOpen(true)}
            >
              <Image
                priority
                alt="google-play-btn"
                height={46}
                src="/assets/pngs/google_play_button.png"
                width={170}
              />
            </button>
            <button
              className={styles['app-download-button']}
              onClick={() => setIsAppStoreModalOpen(true)}
            >
              <Image
                priority
                alt="app-store-btn"
                height={46}
                src="/assets/pngs/app_store_button.png"
                width={170}
              />
            </button>
          </div>
        </section>

        <section className={styles['addition-section']}>
          <Link
            className={styles['addition-description']}
            href="https://www.wello.info/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <p>서비스가</p>
            <p>
              더 궁금한가요? <ChevronRight className={styles.arrow} />
            </p>
          </Link>
          <Link
            className={styles['addition-description']}
            href="https://docviewer.worksmobile.com/webview?sourceId=drive_public_link&sourceFileUrl=https%3A%2F%2Fstorage.worksmobile.com%2Fk1%2Fdrive%2Fr%2F24101%2F300105605%2F300105605%2F100001503536160%2F3472543019027235081%3FfileId%3DMTAwMDAxNTAzNTM2MTYwfDM0NzI1NDMwMTkwMjcyMzUwODF8Rnw1MDAwMDAwMDAwMTgyNjU3MDAx%26sharedLinkId%3DccoBeLBUoOdzNcWNEKGUQw.T97al3V-F26BwsDUwxmYpvzHeIJPtqXPzaBYYPpQeb-HZKj1kLVc_lP2k40sRwS6waxMLWqyjzResQqOCUx8Vw%26link%3DA%26downloadType%3DO"
            rel="noreferrer noopener"
            target="_blank"
          >
            <p>정책 대상자를 찾는</p>
            <p>
              홍보 담당자인가요? <ChevronRight className={styles.arrow} />
            </p>
          </Link>
        </section>
      </aside>
      <Modal
        className={styles.modal}
        opened={isGooglePlayModalOpen}
        onClose={() => setIsGooglePlayModalOpen(false)}
      >
        <div className={styles['modal-title-section']}>
          <h2 className={styles['modal-title']}>앱 다운로드 </h2>
          <button
            className={styles['close-icon']}
            onClick={() => setIsGooglePlayModalOpen(false)}
          >
            <X />
          </button>
        </div>
        <Image
          priority
          alt="google-play"
          height={IMAGE_SIZE}
          src={shareUrl + GOOGLE_PLAY_STORE_SHARE_URL}
          width={IMAGE_SIZE}
        />
        <div className={styles['modal-footer']}>
          <button onClick={() => copyToClipboard(GOOGLE_PLAY_STORE_SHARE_URL)}>
            URL 복사
          </button>
        </div>
      </Modal>
      <Modal
        className={styles.modal}
        opened={isAppStoreModalOpen}
        onClose={() => setIsAppStoreModalOpen(false)}
      >
        <div className={styles['modal-title-section']}>
          <h2 className={styles['modal-title']}>앱 다운로드 </h2>
          <button
            className={styles['close-icon']}
            onClick={() => setIsAppStoreModalOpen(false)}
          >
            <X />
          </button>
        </div>
        <Image
          priority
          alt="app-store"
          height={IMAGE_SIZE}
          src={shareUrl + APPLE_APP_STORE_SHARE_URL}
          width={IMAGE_SIZE}
        />
        <div className={styles['modal-footer']}>
          <button onClick={() => copyToClipboard(APPLE_APP_STORE_SHARE_URL)}>
            URL 복사
          </button>
        </div>
      </Modal>
    </>
  );
};

export default LayoutAsideSection;
