'use client';

import React, { Suspense, memo } from 'react';
import { CookiesProvider } from 'react-cookie';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { LoadingPageTemplate } from '@/components/server';
import { useMountDeviceInfoStore } from '@/stores/DeviceStore';
import { useMountGlobalStore } from '@/stores/GlobalStore';

import { GlobalLoading } from './GlobalLoading';
import { HistoryTracker } from './HistoryTracker';

interface GlobalProviderProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient();

export const GlobalProvider = memo(({ children }: GlobalProviderProps) => {
  useMountGlobalStore();
  useMountDeviceInfoStore();

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider
        defaultSetOptions={{
          path: '/',
        }}
      >
        <Suspense fallback={<LoadingPageTemplate />}>
          <HistoryTracker />
          {children}
          <GlobalLoading />
        </Suspense>
        {process.env.NODE_ENV === 'development' ? (
          <ReactQueryDevtools
            buttonPosition="bottom-left"
            initialIsOpen={false}
          />
        ) : null}
      </CookiesProvider>
    </QueryClientProvider>
  );
});

GlobalProvider.displayName = 'GlobalProvider';
