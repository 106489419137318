import { useCallback } from 'react';

import { useToast } from './useToast';

interface CopyToClipboardParams {
  value?: string;
  message?: string;
}

export const useClipboard = () => {
  const { toast } = useToast();
  /** 파라미터가 없으면 URL을 복사함 */
  const copyToClipboard = useCallback(
    (args?: CopyToClipboardParams) => {
      const value = args?.value ?? window.location.href;
      const message =
        args?.message ?? `${args?.value ? '' : 'URL을 '}클립보드에 저장했어요`;

      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(value)
          .then(() => toast({ message }))
          .catch(() =>
            toast({ message: '클립보드 복사를 실패했어요', type: 'fail' }),
          );
      } else {
        try {
          const textarea = document.createElement('textarea');
          textarea.value = value;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          toast({ message });
        } catch (err) {
          toast({ message: '클립보드 복사를 실패했어요', type: 'fail' });
        }
      }
    },
    [toast],
  );

  return copyToClipboard;
};
