'use client';

import { useEffect } from 'react';
import { Link, Plus } from 'react-feather';

import { cleanClassName, createError, isDev } from '@wello-client/common/utils';
import Image from 'next/image';
import { usePathname, useSearchParams } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { Drawer } from '@/components/client';
import { Spinner } from '@/components/server';
import { ENV, SEARCH_PARAMS } from '@/constants';
import { useCheckWelloApp } from '@/hooks/useCheckWelloApp';
import { useClipboard } from '@/hooks/useClipboard';
import { useToast } from '@/hooks/useToast';
import { MESSAGE_KEYWORD, useHandleApp } from '@/modules/wello-app';
import { useAuthStore } from '@/stores/AuthStore';
import { useDeviceInfoStore } from '@/stores/DeviceStore';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './ShareDrawer.module.scss';

interface KakaoWindow extends Window {
  Kakao?: {
    isInitialized?: () => boolean;
    init?: (clientId: string) => void;
    Share?: {
      sendScrap?: (options: { requestUrl: string }) => void;
    };
  };
}

export const ShareDrawer = () => {
  const [webviewType, osType] = useDeviceInfoStore(
    useShallow((state) => [state.webviewType, state.osType]),
  );

  const { isWelloApp } = useCheckWelloApp();

  const [shareLink, setShareLink] = useGlobalStore(
    useShallow((state) => [state.shareLink, state.setShareLink]),
  );

  const memberCode = useAuthStore((state) => state.myInfo?.memberCode);

  const shareLinkUrl = (() => {
    if (!shareLink) return;

    const pathname = new URL(shareLink).pathname;

    let mainDomain = ENV.NEXT_PUBLIC_DOMAIN;

    if (!isDev) {
      mainDomain = mainDomain.replace('https://', 'https://www.');
    }

    const url = new URL(pathname, mainDomain);

    url.searchParams.set(SEARCH_PARAMS.ENTER_TYPE, 'share');

    if (memberCode) {
      url.searchParams.set(SEARCH_PARAMS.SHARE_BY, memberCode);
    }

    return url.href;
  })();

  const copy = useClipboard();

  const {
    request: checkKakaoInstallRequest,
    response: checkKakaoInstallResponse,
  } = useHandleApp({
    type: MESSAGE_KEYWORD.CHECK_KAKAO_INSTALLED,
  });

  const {
    request: requestShare,
    isPending,
    response,
  } = useHandleApp({
    type: MESSAGE_KEYWORD.SHARE_CONTENT,
  });

  const isKakaoShareResponse: string | undefined = response?.params?.share_type;

  useEffect(() => {
    if (isKakaoShareResponse) {
      setShareLink(null);
    }
  }, [isKakaoShareResponse, setShareLink]);

  useEffect(() => {
    const kakao = (window as KakaoWindow).Kakao;

    if (!kakao) return;

    if (typeof kakao.isInitialized !== 'function') return;

    if (kakao.isInitialized()) return;

    const kakaoClientId = process.env.NEXT_PUBLIC_KAKAO_CLIENT_ID;

    if (!kakaoClientId)
      throw createError({
        return_message: 'Kakao Client ID가 없습니다.',
      });

    kakao.init?.(kakaoClientId);
  }, []);

  const { toast } = useToast();

  const isMobileWeb =
    !webviewType && osType && ['ios', 'android'].includes(osType);

  const isOpened = !!shareLink;

  useEffect(() => {
    if (isOpened) {
      checkKakaoInstallRequest();
    }
  }, [checkKakaoInstallRequest, isOpened]);

  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    setShareLink(null);
  }, [pathname, searchParams, setShareLink]);

  return (
    <Drawer opened={!!shareLink} onClose={() => setShareLink(null)}>
      <div
        className={cleanClassName(
          `${styles['share-drawer-contents']} ${isWelloApp ? '' : styles.web}`,
        )}
      >
        <button
          className={styles['share-button']}
          onClick={() => {
            if (isPending) return;

            try {
              if (isWelloApp) {
                if (checkKakaoInstallResponse?.isSuccess) {
                  requestShare({
                    share_type: 'kakao',
                    url: shareLinkUrl,
                  });
                } else {
                  toast({
                    message: '카카오톡이 설치되어 있지 않아요',
                    type: 'fail',
                  });
                }
              } else {
                if (!shareLinkUrl) return;

                const kakao = (window as KakaoWindow).Kakao;

                kakao?.Share?.sendScrap?.({
                  requestUrl: shareLinkUrl,
                });
              }
            } finally {
              //TODO: 🚀 app store 유저 swift 앱으로 이동 후 해당 분기는 사용하지 않아도 됨
              if (!navigator.userAgent.includes('swift')) {
                setShareLink(null);
              }
            }
          }}
        >
          <div className={`${styles['share-icon-wrap']} ${styles.kakao}`}>
            {isPending ? (
              <Spinner className={styles['kakao-loading-spinner']} />
            ) : (
              <Image
                alt="kakao"
                height={24}
                src="/assets/pngs/kakao.png"
                width={24}
              />
            )}
          </div>
          <h4>카카오톡</h4>
        </button>
        <button
          className={styles['share-button']}
          onClick={() => {
            try {
              if (shareLinkUrl) {
                return copy({
                  value: shareLinkUrl,
                });
              }
            } finally {
              setShareLink(null);
            }
          }}
        >
          <div className={`${styles['share-icon-wrap']} ${styles.link}`}>
            <Link />
          </div>
          <h4>URL 복사</h4>
        </button>
        {isWelloApp || isMobileWeb ? (
          <button
            className={styles['share-button']}
            onClick={() => {
              try {
                if (!shareLinkUrl) return;

                if (isWelloApp)
                  return requestShare({
                    share_type: 'uni',
                    url: shareLinkUrl,
                  });

                if (isMobileWeb)
                  return navigator.share({
                    url: shareLinkUrl,
                  });
              } finally {
                setShareLink(null);
              }
            }}
          >
            <div className={`${styles['share-icon-wrap']} ${styles.more}`}>
              <Plus />
            </div>
            <h4>더 보기</h4>
          </button>
        ) : null}
      </div>
    </Drawer>
  );
};
