import { useRef } from 'react';
import { useCookies } from 'react-cookie';

import jwt from 'jsonwebtoken';

import { COOKIES_KEYS, COOKIE_OPTIONS } from '@/constants';

type Token = string | null;

export const useAccessToken = () => {
  const [cookies, setCookies, removeCookies] = useCookies([
    COOKIES_KEYS.ACCESS_TOKEN,
    COOKIES_KEYS.REFRESH_TOKEN,
  ]);

  const accessToken: Token = cookies.accessToken || null;
  const refreshToken: Token = cookies.refreshToken || null;

  const { current: setter } = useRef({
    setAccessToken: (accessToken: Token) => {
      const removeToken = () =>
        removeCookies(COOKIES_KEYS.ACCESS_TOKEN, COOKIE_OPTIONS);
      try {
        if (accessToken) {
          const decoded = jwt.decode(accessToken) as jwt.JwtPayload;

          if (decoded.exp && decoded.exp * 1000 > Date.now()) {
            return setCookies(COOKIES_KEYS.ACCESS_TOKEN, accessToken, {
              ...COOKIE_OPTIONS,
              expires: new Date(decoded.exp * 1000),
            });
          }
        }

        return removeToken();
      } catch {
        return removeToken();
      }
    },
    setRefreshToken: (refreshToken: Token) => {
      const removeToken = () =>
        removeCookies(COOKIES_KEYS.REFRESH_TOKEN, COOKIE_OPTIONS);
      try {
        if (refreshToken) {
          const decoded = jwt.decode(refreshToken) as jwt.JwtPayload;

          if (decoded.exp && decoded.exp * 1000 > Date.now()) {
            return setCookies(COOKIES_KEYS.REFRESH_TOKEN, refreshToken, {
              ...COOKIE_OPTIONS,
              expires: new Date(decoded.exp * 1000),
            });
          }
        }

        return removeToken();
      } catch {
        return removeToken();
      }
    },
  });

  return { accessToken, refreshToken, ...setter };
};
